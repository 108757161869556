import React, { useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Login = () => {
  const { user, login } = useAuth();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [user, navigate]);

  useEffect(() => {
    window.onTelegramAuth = async (user) => {
      try {
        const response = await axios.get('https://talking-tom-server.vercel.app/api/auth', { params: user });
        
        if (response.data && response.data.id) {
          login(response.data);
          setIsLoggedIn(true);
          navigate('/');
        } else {
          throw new Error('Invalid user data received from server');
        }
      } catch (error) {
        console.error('Authentication failed:', error);
        setError(error.message || 'Authentication failed. Please try again.');
      }
    };

    const script = document.createElement('script');
    script.src = 'https://telegram.org/js/telegram-widget.js?22';
    script.async = true;
    script.setAttribute('data-telegram-login', 'talkingtomAI_bot');
    script.setAttribute('data-size', 'large');
    script.setAttribute('data-onauth', 'onTelegramAuth(user)');
    script.setAttribute('data-request-access', 'write');
    
    const container = document.getElementById('telegram-login-container');
    if (container) {
      container.appendChild(script);
    }

    return () => {
      if (container && container.contains(script)) {
        container.removeChild(script);
      }
    };
  }, [login, navigate]);

  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <div className="card login-card p-4 shadow-sm" style={{ width: '300px' }}>
        <h2 className="text-center mb-4 login-title">Login to Talking Tom</h2>
        <div id="telegram-login-container" className="text-center mb-3"></div>
        {isLoggedIn && <div className="alert alert-success text-center">Login successful!</div>}
        {error && <div className="alert alert-danger text-center">{error}</div>}
      </div>
    </div>
  );
};

export default Login;