import React, { useState, useRef, useEffect } from "react";
import Rive from "@rive-app/react-canvas";
import { Layout, Fit } from "@rive-app/react-canvas";
import { useAuth } from "./AuthContext";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";

function HomePage() {
  const [inputLanguage, setInputLanguage] = useState("en");
  const [outputLanguage, setOutputLanguage] = useState("es");
  const [state, setState] = useState("idle");
  const [transcription, setTranscription] = useState("");
  const [rive, setRive] = useState("idle");
  const [error, setError] = useState(null);
  const [remainingCredits, setRemainingCredits] = useState(0);
  const mediaRecorderRef = useRef(null);
  const speechRecognitionRef = useRef(null);
  const mediaStreamRef = useRef(null);
  const audioRef = useRef(null);
  const { user, logout } = useAuth();
  const navigate = useNavigate();


 // Language mapping
  const languageNames = {
    af: "Afrikaans",
    ar: "Arabic",
    hy: "Armenian",
    az: "Azerbaijani",
    be: "Belarusian",
    bs: "Bosnian",
    bg: "Bulgarian",
    ca: "Catalan",
    zh: "Chinese",
    hr: "Croatian",
    cs: "Czech",
    da: "Danish",
    nl: "Dutch",
    en: "English",
    et: "Estonian",
    fi: "Finnish",
    fr: "French",
    gl: "Galician",
    de: "German",
    el: "Greek",
    he: "Hebrew",
    hi: "Hindi",
    hu: "Hungarian",
    is: "Icelandic",
    id: "Indonesian",
    it: "Italian",
    ja: "Japanese",
    kn: "Kannada",
    kk: "Kazakh",
    ko: "Korean",
    lv: "Latvian",
    lt: "Lithuanian",
    mk: "Macedonian",
    ms: "Malay",
    mr: "Marathi",
    mi: "Maori",
    ne: "Nepali",
    no: "Norwegian",
    fa: "Persian",
    pl: "Polish",
    pt: "Portuguese",
    ro: "Romanian",
    ru: "Russian",
    sr: "Serbian",
    sk: "Slovak",
    sl: "Slovenian",
    es: "Spanish",
    sw: "Swahili",
    sv: "Swedish",
    tl: "Tagalog",
    ta: "Tamil",
    th: "Thai",
    tr: "Turkish",
    uk: "Ukrainian",
    ur: "Urdu",
    vi: "Vietnamese",
    cy: "Welsh",
  };

  useEffect(() => {
    fetchRemainingCredits();
  }, []);

  useEffect(() => {
    if (state === "playing") {
      setRive("Talk");
    } else if (state === "idle") {
      setRive("idle");
    } else if (state === "listening") {
      setRive("idle");
    } else if (state === "processing") {
      setRive("Talk");
    }
  }, [state]);

  useEffect(() => {
    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;
    if (SpeechRecognition) {
      const recognition = new SpeechRecognition();
      recognition.continuous = true;
      recognition.interimResults = true;
      recognition.lang = inputLanguage;
      recognition.onresult = handleResult;
      speechRecognitionRef.current = recognition;
    } else {
      console.error("Speech Recognition is not available.");
    }
    return () => {
      speechRecognitionRef.current?.stop();
      stopMedia();
      stopAudio();
    };
  }, [inputLanguage]);

  const fetchRemainingCredits = async () => {
    try {
      const response = await fetch(
        `https://talking-tom-server.vercel.app/api/fetchcredits?session_token=${user.session_token}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setRemainingCredits(data.remainingCredits);
      } else {
        console.error("Failed to fetch remaining credits");
        setError("Failed to fetch remaining credits. Please try refreshing the page.");
      }
    } catch (error) {
      console.error("Error fetching remaining credits:", error);
      setError("An error occurred while fetching credits. Please try refreshing the page.");
    }
  };

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  const swapLanguages = () => {
    setInputLanguage(outputLanguage);
    setOutputLanguage(inputLanguage);
  };

  const handleResult = (event) => {
    const transcript = Array.from(event.results)
      .map((result) => result[0])
      .map((result) => result.transcript)
      .join("");
    setTranscription(`You: ${transcript}`);
  };

  const startRecording = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        mediaStreamRef.current = stream;
        const recorder = new MediaRecorder(stream);
        recorder.ondataavailable = handleAudioData;
        recorder.onstop = stopMedia;
        recorder.start();
        speechRecognitionRef.current?.start();
        mediaRecorderRef.current = recorder;

        const audio = new Audio();
        audio.addEventListener("play", () => {
          setRive("Talk");
        });
        audioRef.current = audio;

        setState("listening");
      })
      .catch((error) => {
        console.error("Error accessing the microphone:", error);
        setState("idle");
      });
  };

  const handleAudioData = (event) => {
    // Process audio data here if needed
  };

  const stopMedia = () => {
    mediaStreamRef.current?.getTracks().forEach((track) => track.stop());
    mediaRecorderRef.current?.stop();
    speechRecognitionRef.current?.stop();
    setState("processing");
    const cleanTranscription = transcription.replace("You: ", "");
    if (cleanTranscription.length > 3) {
      sendTranscript(cleanTranscription);
    } else {
      setState("idle");
    }
  };

  const stopAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      audioRef.current = null;
    }
    setState("idle");
  };

  const sendTranscript = async (transcript) => {
    try {
      // Fetch the translated text
      const translationResponse = await fetch(
        `https://worker-wispy-lake-abcf.divya-ranjn.workers.dev/?text=${encodeURIComponent(
          transcript
        )}&fromLanguage=${inputLanguage}&toLanguage=${outputLanguage}&translate=true&session_token=${
          user.session_token
        }`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
          },
        }
      );

      if (!translationResponse.ok) {
        if (translationResponse.status === 401) {
          // Handle unauthorized access
          logout();
          navigate("/login");
          return;
        }
        if (translationResponse.status === 429) {
          // Handle daily limit reached
          setError("You've reached your daily request limit. Please try again tomorrow.");
          setState("idle");
          setRive("idle");
          return;
        }
        throw new Error("Network response was not ok");
      }

      // Reset error state if request is successful
      setError(null);

      const translationData = await translationResponse.json();
      const translatedText = translationData.translatedText;
      setRemainingCredits(translationData.remainingCredits);
      setTranscription(`Translation: ${translatedText}`);

      // Fetch the audio using the translated text
      const audioResponse = await fetch(
        `https://worker-wispy-lake-abcf.divya-ranjn.workers.dev/?text=${encodeURIComponent(
          translatedText
        )}&audio=true&session_token=${user.session_token}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
          },
        }
      );

      if (!audioResponse.ok) {
        if (audioResponse.status === 401) {
          // Handle unauthorized access
          logout();
          navigate("/login");
          return;
        }
        throw new Error("Network response was not ok");
      }

      const audioArrayBuffer = await audioResponse.arrayBuffer();
      const audioBlob = new Blob([audioArrayBuffer], { type: "audio/mpeg" });
      const audioUrl = URL.createObjectURL(audioBlob);

      // Update remaining credits from header
      const newRemainingCredits = audioResponse.headers.get('X-Remaining-Credits');
      if (newRemainingCredits) {
        setRemainingCredits(parseInt(newRemainingCredits, 10));
      }

      audioRef.current = new Audio(audioUrl);
      audioRef.current.play();
      setState("playing");

      audioRef.current.addEventListener("playing", () => {
        setRive("Talk");
      });

      audioRef.current.onended = () => {
        URL.revokeObjectURL(audioUrl);
        setState("idle");
        setTranscription("");
        setRive("idle");
      };
    } catch (error) {
      console.error("Error processing request:", error);
      setError("An error occurred while processing your request. Please try again.");
      setState("idle");
      setRive("idle");
    }
  };

  const handleButtonClick = () => {
    switch (state) {
      case "idle":
        setTranscription("");
        startRecording();
        break;
      case "listening":
        setRive("Hands_up");
        stopMedia();
        break;
      case "processing":
        setRive("Talk");
        break;
      case "playing":
        stopAudio();
        setTranscription("");
        break;
      default:
        break;
    }
  };

  return (
    <div className="d-flex flex-column vh-100 container w-50">
      <div className="mt-3 px-3 pt-3">
        <div className="dropdown">
          <span
            className="dropdown-toggle"
            id="dropdownMenuButton"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Welcome, {user.first_name}!
          </span>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <li>
              <a className="dropdown-item" href="#" onClick={handleLogout}>
                Logout
              </a>
            </li>
          </ul>
          <span className="credit-info mt-3">⚡{remainingCredits}</span>
        </div>
      </div>
      <div className="mt-3 px-3 pt-3">
        <h2 className="main-title">Hi, I'm Tom</h2>
        <p className="faded">I speak all languages</p>
      </div>
      <div className="flex-grow-1 d-flex align-items-center justify-content-center">
        <div className="rive-box mb-3">
          <Rive
            key={state}
            src="/tom.riv"
            stateMachines={rive}
            autoPlay={true}
            className="rive-animation"
            layout={new Layout({ fit: Fit.Cover })}
          />
        </div>
      </div>
      <div className="mb-3 px-3">
        {error && <p className="error-message">{error}</p>}
        {state !== "idle" && (
          <p className="transcript-style">{transcription}</p>
        )}
        <div className="input-group mb-4">
          <select
            className="form-control"
            value={inputLanguage}
            onChange={(e) => setInputLanguage(e.target.value)}
            disabled={remainingCredits === 0}
          >
            {Object.keys(languageNames).map((lang) => (
              <option key={lang} value={lang}>
                {languageNames[lang]}
              </option>
            ))}
          </select>
          <button
            className="input-group-text mt-1"
            onClick={swapLanguages}
            disabled={remainingCredits === 0}
          >
            <img
              src="/switch.svg"
              className="switch-img"
              alt="Switch languages"
            />
          </button>
          <select
            className="form-control"
            value={outputLanguage}
            onChange={(e) => setOutputLanguage(e.target.value)}
            disabled={remainingCredits === 0}
          >
            {Object.keys(languageNames).map((lang) => (
              <option key={lang} value={lang}>
                {languageNames[lang]}
              </option>
            ))}
          </select>
        </div>
        <button
          className="btn btn-full mt-2"
          onClick={handleButtonClick}
          disabled={remainingCredits === 0 || state === "processing" || state === "playing"}
        >
          {remainingCredits === 0
            ? "No credits left"
            : state === "idle"
            ? "Speak"
            : state === "listening"
            ? "Translate"
            : "Processing..."}
        </button>
      </div>
    </div>
  );
}

export default HomePage;