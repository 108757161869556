import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import axios from 'axios';

const TelegramEntry = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [error, setError] = useState(null);

  useEffect(() => {
    const handleTelegramAuth = async () => {
      console.log('TelegramEntry component mounted');
      if (window.Telegram && window.Telegram.WebApp) {
        console.log('Telegram WebApp detected');
        const initData = window.Telegram.WebApp.initData;
        console.log('Telegram WebApp initData:', initData);
        
        const storedUser = localStorage.getItem('talkingTomUser');
        const storedSessionToken = storedUser ? JSON.parse(storedUser).session_token : null;

        if (initData || storedSessionToken) {
          try {
            console.log('Sending auth data to server');
            const response = await axios.post('https://talking-tom-server.vercel.app/api/tg-auth', { 
              initData, 
              storedSessionToken 
            });
            console.log('Telegram WebApp authentication response:', response.data);
            if (response.data && response.data.id) {
              console.log('Login successful, navigating to home');
              login(response.data);
              navigate('/');
            } else {
              throw new Error('Invalid user data received from server');
            }
          } catch (error) {
            console.error('Telegram WebApp authentication failed:', error);
            let errorMessage = 'Authentication failed. Please try again.';
            if (error.response) {
              console.error('Error response:', error.response.data);
              errorMessage = error.response.data.error || errorMessage;
            }
            setError(errorMessage);
            console.log('Setting error and scheduling redirect');
            setTimeout(() => {
              console.log('Redirecting to login page');
              navigate('/login');
            }, 5000);
          }
        } else {
          console.log('No initData or stored session found, redirecting to login');
          navigate('/login');
        }
      } else {
        console.log('Telegram WebApp not detected, redirecting to login');
        navigate('/login');
      }
    };
    handleTelegramAuth();
  }, [login, navigate]);

  if (error) {
    return <div>Error: {error}. Redirecting to login in 5 seconds...</div>;
  }

  return null;
};

export default TelegramEntry;