import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const validateAndRefreshToken = async () => {
      const savedUser = localStorage.getItem('talkingTomUser');
      if (savedUser) {
        const parsedUser = JSON.parse(savedUser);
        try {
          const response = await axios.post('https://talking-tom-server.vercel.app/api/validate-token', {
            session_token: parsedUser.session_token
          });
          if (response.data.valid) {
            setUser(parsedUser);
          } else if (response.data.newToken) {
            const updatedUser = { ...parsedUser, ...response.data };
            localStorage.setItem('talkingTomUser', JSON.stringify(updatedUser));
            setUser(updatedUser);
          } else {
            // Token is invalid and couldn't be refreshed
            logout();
          }
        } catch (error) {
          console.error('Error validating token:', error);
          logout();
        }
      }
      setIsLoading(false);
    };

    validateAndRefreshToken();
  }, []);

  const login = (userData) => {
    localStorage.setItem('talkingTomUser', JSON.stringify(userData));
    setUser(userData);
  };

  const logout = () => {
    localStorage.removeItem('talkingTomUser');
    setUser(null);
  };

  if (isLoading) {
    return <div>Loading...</div>; // Or any loading indicator
  }

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => React.useContext(AuthContext);
